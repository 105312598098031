// src/styles/styles.scss

$primary-color: #1890ff;

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.steps-content {
  margin-top: 24px;
}

.steps-action {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;

  .ant-btn {
    margin-left: 8px;
  }
}

@media (max-width: 768px) {
  .steps-action {
    flex-direction: column;
    align-items: stretch;

    .ant-btn {
      width: 100%;
      margin-left: 0;
      margin-bottom: 8px;
    }
  }
}

/* styles/styles.scss */

/* Footer Section */
.footer-section {
  padding: 30px 20px;
  background-color: #001529; /* Dark Blue Background */
  color: #ffffff !important; /* White Text */
  text-align: center;
  .ant-typography {
    color: #ffffff !important; /* White Text */
  }
  /* Footer Content Alignment */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* Ensure footer sticks to the bottom */
  width: 100%;
}

/* Contact Information */
.footer-section .contact-info {
  margin-bottom: 20px;

  a {
    color: #ffffff;
    text-decoration: none;
    margin: 0 10px;

    &:hover {
      color: #1890ff; /* Ant Design Primary Color on Hover */
    }
  }
}

/* Social Media Icons */
.footer-section .social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  a {
    display: inline-block;
    margin: 0 10px;

    img {
      width: 24px;
      height: 24px;
      transition: transform 0.3s;

      &:hover {
        transform: scale(1.2);
      }
    }
  }
}

/* Additional Footer Text */
.footer-section .additional-info {
  font-size: 14px;
  color: #ffffff;

  strong {
    display: block;
    margin-bottom: 10px;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-section {
    padding: 20px 10px;
  }

  .footer-section .social-icons img {
    width: 20px;
    height: 20px;
  }

  .footer-section .additional-info {
    font-size: 12px;
  }
}
